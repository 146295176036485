
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AuthModule from "@/store/auth";
@Component({
  name: "Withdrawn",
  components: {
    Header,
    Footer,
  },
})
export default class RegisterSuccess extends Vue {
  private mounted() {
    AuthModule.changeMe(null);
  }
}
